import Vue from 'vue';
import colors from 'vuetify/lib/util/colors'
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#358f94', // Ihre ursprüngliche Primärfarbe
                secondary: '#a4a4a4', // Ihre neue sekundäre Farbe
                tertiary: '#000000',
                lianeButton: '#7b2b6b',
                homeButton: '#f98918',
                background: '#f98918',
                accent: '#f1f8f9', // Weitere Farben können nach Bedarf hinzugefügt werden
                success: '#3dd23d',
                grey: '#F7F7F7',
                error: colors.red.accent3,
            },
            dark: {
                primary: colors.blue.lighten3,
            },
        },
    },
});
