import {Insurance} from "@/models/System";

export  const defaultInsurances: Insurance[] = [
    {
        "id": "208",
        "name": "AOK Baden-Württemberg"
    },
    {
        "id": "77",
        "name": "AOK Bayern"
    },
    {
        "id": "100",
        "name": "AOK Bremen/Bremerhaven"
    },
    {
        "id": "125",
        "name": "AOK Hessen"
    },
    {
        "id": "200",
        "name": "AOK Niedersachsen"
    },
    {
        "id": "98",
        "name": "AOK Nordost"
    },
    {
        "id": "214",
        "name": "AOK Nordwest"
    },
    {
        "id": "161",
        "name": "AOK Plus"
    },
    {
        "id": "177",
        "name": "AOK Rheinland-Pfalz/Saarland"
    },
    {
        "id": "221",
        "name": "AOK Rheinland/Hamburg"
    },
    {
        "id": "99",
        "name": "AOK Sachsen-Anhalt"
    },
    {
        "id": "194",
        "name": "Audi BKK"
    },
    {
        "id": "209",
        "name": "AXA"
    },
    {
        "id": "129",
        "name": "BAHN-BKK"
    },
    {
        "id": "103",
        "name": "BARMER"
    },
    {
        "id": "186",
        "name": "Bertelsmann BKK"
    },
    {
        "id": "153",
        "name": "BIG direkt gesund"
    },
    {
        "id": "152",
        "name": "BKK Achenbach Buschhütten"
    },
    {
        "id": "164",
        "name": "BKK Akzo Nobel"
    },
    {
        "id": "203",
        "name": "BKK B. Braun Aesculap AG"
    },
    {
        "id": "246",
        "name": "BKK BPW"
    },
    {
        "id": "118",
        "name": "BKK Deutsche Bank"
    },
    {
        "id": "165",
        "name": "BKK Diakonie"
    },
    {
        "id": "149",
        "name": "BKK DürkoppAdler"
    },
    {
        "id": "131",
        "name": "BKK Euregio"
    },
    {
        "id": "248",
        "name": "BKK evm"
    },
    {
        "id": "244",
        "name": "BKK EWE"
    },
    {
        "id": "132",
        "name": "BKK exklusiv"
    },
    {
        "id": "180",
        "name": "BKK Faber-Castell & Partner"
    },
    {
        "id": "108",
        "name": "BKK firmus"
    },
    {
        "id": "190",
        "name": "BKK Freudenberg"
    },
    {
        "id": "114",
        "name": "BKK GILDEMEISTER SEIDENSTICKER"
    },
    {
        "id": "159",
        "name": "BKK Groz-Beckert"
    },
    {
        "id": "171",
        "name": "BKK HENSCHEL Plus"
    },
    {
        "id": "133",
        "name": "BKK Herkules"
    },
    {
        "id": "250",
        "name": "BKK Karl Mayer eKV"
    },
    {
        "id": "128",
        "name": "BKK Linde"
    },
    {
        "id": "236",
        "name": "BKK MAHLE"
    },
    {
        "id": "189",
        "name": "bkk melitta hmr"
    },
    {
        "id": "126",
        "name": "BKK Merck"
    },
    {
        "id": "224",
        "name": "BKK Miele"
    },
    {
        "id": "169",
        "name": "BKK MTU Friedrichhafen"
    },
    {
        "id": "238",
        "name": "BKK PFAFF"
    },
    {
        "id": "136",
        "name": "BKK Pfalz"
    },
    {
        "id": "202",
        "name": "BKK ProVita"
    },
    {
        "id": "228",
        "name": "BKK Public"
    },
    {
        "id": "219",
        "name": "BKK PwC"
    },
    {
        "id": "226",
        "name": "BKK Salzgitter"
    },
    {
        "id": "247",
        "name": "BKK SBH"
    },
    {
        "id": "239",
        "name": "BKK Scheufelen"
    },
    {
        "id": "206",
        "name": "BKK Stadt Augsburg"
    },
    {
        "id": "211",
        "name": "BKK Technoform"
    },
    {
        "id": "245",
        "name": "BKK Textilgruppe Hof"
    },
    {
        "id": "110",
        "name": "BKK VBU"
    },
    {
        "id": "137",
        "name": "BKK VDN"
    },
    {
        "id": "157",
        "name": "BKK VerbundPlus"
    },
    {
        "id": "120",
        "name": "BKK Voralb"
    },
    {
        "id": "182",
        "name": "BKK Werra-Meissner"
    },
    {
        "id": "225",
        "name": "BKK Wirtschaft & Finanzen"
    },
    {
        "id": "232",
        "name": "BKK Würth"
    },
    {
        "id": "166",
        "name": "BKK ZF & Partner"
    },
    {
        "id": "173",
        "name": "BKK24"
    },
    {
        "id": "87",
        "name": "BMW BKK"
    },
    {
        "id": "205",
        "name": "Bosch BKK"
    },
    {
        "id": "73",
        "name": "DAK-Gesundheit"
    },
    {
        "id": "240",
        "name": "Debeka BKK"
    },
    {
        "id": "140",
        "name": "DIE BERGISCHE KRANKENKASSE"
    },
    {
        "id": "141",
        "name": "Die Continentale BKK"
    },
    {
        "id": "97",
        "name": "DKV"
    },
    {
        "id": "142",
        "name": "energie-BKK"
    },
    {
        "id": "216",
        "name": "Ernst & Young BKK"
    },
    {
        "id": "188",
        "name": "Heimat Krankenkasse"
    },
    {
        "id": "105",
        "name": "HEK"
    },
    {
        "id": "183",
        "name": "hkk"
    },
    {
        "id": "218",
        "name": "IKK - Die Innovationskasse"
    },
    {
        "id": "199",
        "name": "IKK Brandenburg und Berlin"
    },
    {
        "id": "84",
        "name": "IKK classic"
    },
    {
        "id": "154",
        "name": "IKK gesund plus"
    },
    {
        "id": "143",
        "name": "IKK Südwest"
    },
    {
        "id": "151",
        "name": "KKH"
    },
    {
        "id": "230",
        "name": "Knappschaft"
    },
    {
        "id": "196",
        "name": "Koenig & Bauer BKK"
    },
    {
        "id": "204",
        "name": "Krones BKK"
    },
    {
        "id": "249",
        "name": "LKK"
    },
    {
        "id": "242",
        "name": "LVM Krankenversicherung AG"
    },
    {
        "id": "121",
        "name": "Mercedes-Benz BKK"
    },
    {
        "id": "212",
        "name": "mhplus BKK"
    },
    {
        "id": "135",
        "name": "Mobil Krankenkasse"
    },
    {
        "id": "144",
        "name": "Novitas BKK"
    },
    {
        "id": "122",
        "name": "PBHH"
    },
    {
        "id": "145",
        "name": "Pronova BKK"
    },
    {
        "id": "146",
        "name": "R+V BKK"
    },
    {
        "id": "106",
        "name": "Salus BKK"
    },
    {
        "id": "111",
        "name": "SBK"
    },
    {
        "id": "113",
        "name": "SECURVITA"
    },
    {
        "id": "178",
        "name": "SKD BKK"
    },
    {
        "id": "175",
        "name": "Südzucker BKK"
    },
    {
        "id": "999",
        "name": "Testumgebung"
    },
    {
        "id": "89",
        "name": "TK"
    },
    {
        "id": "227",
        "name": "TUI BKK"
    },
    {
        "id": "138",
        "name": "VIACTIV Krankenkasse"
    },
    {
        "id": "109",
        "name": "vivida BKK"
    },
    {
        "id": "235",
        "name": "WMF BKK"
    },
    {
        "id": "2016",
        "name": "HanseMerkur KV AG"
    },
    {
        "id": "2015",
        "name": "Barmenia Pflegeversicherung"
    },
    {
        "id": "2014",
        "name": "Generali Deutschland KV AG"
    },
    {
        "id": "2013",
        "name": "DBV - Deutsche Beamtenversicherung"
    },
    {
        "id": "2012",
        "name": "Union Krankenversicherung PV"
    },
    {
        "id": "2011",
        "name": "KVB PV"
    },
    {
        "id": "2010",
        "name": "AXA Krankenversicherung AG"
    },
    {
        "id": "2009",
        "name": "HUK-Coburg"
    },
    {
        "id": "2008",
        "name": "Continentale KV a.G."
    },
    {
        "id": "2007",
        "name": "SIGNAL IDUNA Krankenversicherung a. G."
    },
    {
        "id": "2006",
        "name": "Landeskrankenhilfe V.V.a.G"
    },
    {
        "id": "2005",
        "name": "DKV PV"
    },
    {
        "id": "2004",
        "name": "Postbeamtenkrankenkasse (PBeaKK)"
    },
    {
        "id": "2003",
        "name": "Gothaer Krankenversicherung AG"
    },
    {
        "id": "2002",
        "name": "Debeka PV"
    },
    {
        "id": "2001",
        "name": "Allianz Private KV AG"
    },
    {
        "id": "2000",
        "name": "Privatversichert"
    }
]
