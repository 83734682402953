import '@mdi/font/css/materialdesignicons.min.css'
import Vue from 'vue'
import App from './App.vue'
import store from './system/store'
import router from "./system/router";
import vuetify from './system/vuetify'
import FlagIcon from "vue-flag-icon";
import VueSignaturePad from "vue-signature-pad";
import VueObserveVisibility from 'vue-observe-visibility';


Vue.use(FlagIcon);
Vue.use(VueSignaturePad);
Vue.use(VueObserveVisibility);


router.beforeEach((to, from, next) => {
  if (
    to.path === '/boxauswahl' ||
    to.path === '/'
  ) {
    next()
  } else {
    if (store.state.system.selectedProducts.length === 0) {
      next()
    } else {
      // Wenn der Store nicht leer ist, lasse die Navigation zu
      next()
    }
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  beforeCreate() {
    this.$store.dispatch('initialiseStore').then();
  },
  render: h => h(App)
}).$mount('#app')
