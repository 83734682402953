<template>

    <div class="header-content">

      <!-- Logo- und Button-Bereich für Mobile -->
      <div v-if="isMobileDevice" class="mobile-container">
        <router-link to="/">
          <img height="80" :src="logoUrl" alt="Logo" class="mobile-logo" />
        </router-link>
      </div>

      <!-- Logo für Desktop -->
      <router-link to="/" v-if="!isMobileDevice">
        <img height="60" :src="logoUrl" alt="Logo" />
      </router-link>

      <span class="header-announcement" v-if="$vuetify.breakpoint.lgAndUp">
        Mehr Schutz und Sicherheit <br>in der häuslichen Pflege!
      </span>


      <!-- ... Ihr anderer Code ... -->
      <v-spacer v-if="!isOnline"/>
      <v-icon v-if="!isOnline" color="error">mdi-wifi-remove</v-icon>
      <span class="ml-2 error--text" v-if="!isOnline">Kein Internet</span>
      <v-spacer />




    </div>

</template>

<!-- ... Ihr anderer Code ... -->

<style scoped>
/* ... Ihre anderen Styles ... */

.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>


<script>
import headerInfo from "@/indivconfig/headerInfo.json";
import { useOnline } from '@vueuse/core'

export default {
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
  },
  name: 'PB-Header',
  data: () => ({
    logoUrl: headerInfo.logoUrl,
    phoneNumber: headerInfo.phoneNumber,
    homeSiteLink: headerInfo.url,
    isMobileDevice: /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }),

  computed: {
    isOnline(){
      return useOnline()?.value
    }
  }
}
</script>

<style scoped>
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  background: linear-gradient(to left, #7b2b6b 0%, transparent 90%);
}

.mobile-logo {
  height: 45px; /* Reduzierte Größe für das Logo */
  margin-bottom: 10px;

}

.header-announcement {
  font-size: 1.8em;
  font-weight: bold;
  text-decoration: underline;
  color: white;
  margin-right: 7%;
  text-align: right; /* Text rechts ausrichten */
  width: 100%; /* Element nimmt volle Breite ein */
}

.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Stellen Sie sicher, dass es die gesamte Breite des Containers einnimmt */
}

.call-btn {
  font-weight: 600;
  font-size: 1.2em;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
}

.call-icon {
  font-weight: 1200;
  width: 25px; /* Reduzierte Größe für das Icon */
  height: 25px;
  margin-right: 8px;
}

.mobile-call-btn {
  margin-top: 5px; /* Etwas weniger Abstand */
  font-size: 0.9em; /* Reduzierte Schriftgröße */
}


@media (max-width: 768px) { /* oder ein anderer Breakpoint, je nachdem was für Sie sinnvoll ist */

  .header-content {
    background: transparent !important;  /* Setzt den Hintergrund auf dem Handy auf transparent */
    border-bottom: 10px solid #7b2b6b; /* Fügt einen unteren Balken hinzu */
  }


  .mobile-logo {
    height: auto; /* Lassen Sie das Logo seine natürliche Größe annehmen und von der Breite bestimmt werden */
    max-width: 70%; /* Dies stellt sicher, dass das Logo nicht zu breit wird */
  }

  .mobile-container {
    align-items: center; /* Horizontal zentrieren */
    text-align: center;
  }

  .header-content > .mobile-container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .mobile-container > router-link {
    display: block;
    text-align: center;
  }


  .call-icon {
    width: 20px;
    height: 20px;
  }
}


</style>
