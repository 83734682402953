<template>
  <v-container class="pa-4">
    <p-b-stepper step="4"/>
    <div class="step4 pt-4">
      <h1 class="text-xl sm:text-h1 text-secondary font-medium mb-4 mb-4"> Vielen Dank für Ihre Bestellung! </h1>
      <v-row>
        <v-col md="6">
          <div class="text-gray mb-8 text-xl"> Wir haben Ihre Anfrage erhalten und setzen uns umgehend mit Ihrer Krankenkasse in Verbindung. Ihre erste Lieferung wird so schnell wie möglich erfolgen und anschließend gemäß dem von Ihnen gewünschten Lieferintervall fortgesetzt</div>
        </v-col>
      </v-row>
      <v-btn @click="goToLink(homeSiteLink)"
             color="lianeButton"
             block
             outlined>
        Zurück zur Startseite
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import homesiteLink from "@/indivconfig/headerInfo.json";

import PBStepper from "@/components/PB-Stepper.vue";
import {mapFields} from "vuex-map-fields";

export default {
  components: {PBStepper},
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
  },

  computed: {
    ...mapFields({
      firstDelivery: 'customer.firstDelivery',
    }),
  },


  data() {
    return {
      homeSiteLink: homesiteLink.url,
    };
  },
};
</script>

<style scoped>

</style>
