<template xmlns="http://www.w3.org/1999/html">
  <div>
    <p-b-stepper step="2"/>
    <div class="content-containerIntervall">
      <v-row class="justify-center align-center">

      <!-- Linke Spalte -->
      <v-col cols="12" lg="7" md="7" sm="12" xs="12" class="justify-center elevation-3">

        <v-row class="justify-center">
          <v-col cols="12">
            <v-card rounded>
              <v-card-text>
                <h1 class="text-h4" style="color: #3aa313">Ab wann möchten Sie Ihre Lieferung erhalten ?</h1>
              </v-card-text>

              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <v-card outlined>
                      <v-date-picker v-model="customer.firstDelivery" :allowed-dates="allowedDates" locale="de"
                                     color="lianeButton"

                                     full-width
                      ></v-date-picker>
                    </v-card>
                  </v-col>
                  <v-col class="justify-center align-center text-center fill-height">
                    <h2 class="text-h4" style="color: #3aa313" >Wie häufig möchten Sie Ihre Pflegebox erhalten?</h2>

                    <v-row :class="{'mt-8': !$vuetify.breakpoint.smAndDown}" >
                      <v-col v-show="showInterVal" cols="12" xl="6" lg="6" md="12" sm="12"
                             v-for="(d, index) in deliveryIntervals"
                             :key="index">

                        <v-btn
                            :outlined="customer.deliveryInterval !== d.value"
                            color="lianeButton"
                            block
                            class="text-lg hidden-md-and-down white--text"
                            large
                            rounded
                            :class="{ 'mr-5': index < 3 }"
                            @click="setInterval(d.value)"
                        >
                          <div class="pa-5">
                            {{ d.text }}
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row class="hidden-lg-and-up">
                      <v-col>
                        <v-autocomplete
                            v-model="customer.deliveryInterval"
                            outlined
                            label="Interval"
                            :items="deliveryIntervals"
                            item-text="text"
                            item-value="value"
                            @change="setInterval($event)"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>


                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>


          </v-col>


        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-space-between align-center"> <!-- Flexbox-Utility-Klassen -->

            <!-- Zurück Button für sm and down -->
            <v-btn v-if="$vuetify.breakpoint.smAndDown" @click="goToLastStep()" text outlined x-large>
              Zurück
            </v-btn>

            <!-- Zurück Button für md and up -->
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="mt-16" @click="goToLastStep()" text outlined x-large>
              Zurück
            </v-btn>

            <!-- Weiter Button für sm and down -->
            <v-btn v-if="$vuetify.breakpoint.smAndDown" color="lianeButton" class="white--text" @click="goToNextStep()" large>
              Zum letzten Schritt
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
            <!-- Weiter Button für md and up -->
            <v-btn v-else color="lianeButton" class="text-h5 white--text mt-16" @click="goToNextStep()" x-large>
              Zum letzten Schritt
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>

          </v-col>
        </v-row>

      </v-col>

      <!-- Rechte Spalte -->
        <v-col cols="0" md="5" lg="5" class="d-none d-md-block">
        <img
            src="static/images/Liefertermin.webp"
            alt="Rechtes Bild"
            class="responsive-image-right"
        />
      </v-col>
    </v-row>





    <v-form ref="form" v-model="valid" lazy-validation class="mt-4">

    </v-form>


    <p-b-snackbar :msg="snackbarMessage"></p-b-snackbar>

  </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import PBStepper from "@/components/PB-Stepper.vue";
import PBDatepicker from "@/components/PB-Datepicker.vue";
import moment from "moment";
import PBSnackbar from "@/components/PBSnackbar.vue";
import {mapFields} from 'vuex-map-fields';
import collectiveAddress from '@/indivconfig/collectiveAddress.json'
import releaseJson from "@/internaldata/release.json"
import store from "../system/store";
import {defineComponent} from "vue";
import Vue from "vue";

export default defineComponent({
  name: 'Step2ComponentLieferintervall',
  components: {PBSnackbar, PBDatepicker, PBStepper},
  data() {
    return {
      showSnackbar: false,
      snackbarMessage: {},
      menu: false,
      valid: true,
      isConsultant: false,
      now: moment().format("YYYY-MM-DD"),
      differentDelivery: false,
      showInterVal: true,
    };
  },

  mounted() {
    if (!this.customer.firstDelivery) this.customer.firstDelivery = moment(moment.now()).format("YYYY-MM-DD").toString()
    if (!this.customer.deliveryInterval) this.customer.deliveryInterval = this.customer.firstDelivery?.match('(\\d{1,2})(?=\\D*$)')[0]
  },

  computed: {
    ...mapFields([
      'customer',
      'customer.collectiveAddressId',
      'additionalInformation.consultantNumber',
      'system.insurances',
      'system.selectedProducts',
      'system.products',
      'system.countryCodes',
    ]),


    deliveryIntervals() {
      return [
        {text: 'Monatlich', value: this.getFirstDelivery()},
        {text: 'alle 2 Monate', value: '1/6'},
        {text: 'Pro Quartal', value: '1/4'},
        {text: 'Pro Halbjahr', value: '1/2'},
      ]
    }
  },

  methods: {
    ...mapMutations(["increase"]),

    setInterval(intervall){
      this.showInterVal = false
      this.customer.deliveryInterval = intervall
      this.showInterVal = true
    },


    getFirstDelivery(){
      const dayMatch = this.customer.firstDelivery?.match('(\\d{1,2})(?=\\D*$)')
      return dayMatch ? dayMatch[0] : ""
    },

    onCheckboxChange() {
      this.increase('WB')
      this.snackbarMessage = {
        text: 'Wir haben dreimal den wiederverwendbaren Bettschutz zu Ihrem Warenkorb hinzugefügt.',
        type: 'success'
      };
    },
    allowedDates(date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // setzt die Zeit auf Mitternacht, um nur das Datum zu vergleichen

      // Add 7 days to the current date
      const startDate = new Date(currentDate.getTime());
      startDate.setDate(currentDate.getDate() + 7);

      // Prüft, ob das übergebene Datum gleich oder nach dem Startdatum liegt
      return new Date(date) >= startDate;
    },

    async goToLastStep() {
      this.$router.push('/dateneingabe').then();
      this.$vuetify.goTo(0).then()
    },

    async goToNextStep() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        this.snackbarMessage = {text: 'Bitte füllen Sie alle Pflichtfelder aus.', type: 'warning'};
      } else {
        this.$router.push('/unterschrift').then()
        this.$vuetify.goTo(0).then()
      }
    }
  },
  created() {
    this.countryCodes.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  },
});
</script>


<style>

.content-containerIntervall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh; /* Nehmen Sie die volle Höhe des Viewports */
  padding: 0 15px; /* Einige zusätzliche Polsterung an den Seiten, falls gewünscht */
}

.responsive-image-right {
  max-width: 100%;
  height: auto;
}

.gradient-primary {
  background: linear-gradient(315deg, #f9ad18, #f98918 80%);
  color: white; /* Setzen Sie eine Farbe für den Text, die gut mit dem Gradienten harmoniert */
}
</style>